
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) 点击列表上的分享按钮`,
's37a9e8aec5713460': `详细资讯`,
's5c6aad7a7e4a1437': `3) 点击加入主画面`,
's6196153c4b0c1ea0': `安装`,
's8d0173763d91830c': `以App的方式安装到主画面，提高用户体验以及操作便捷性`,
'sa5ef80b4bb9b39f8': `简短资讯`,
'sc79fd7641eb9a975': `隐藏安装流程`,
'scdaf4bbff76674c8': `将App加入主画面`,
'sdca23702207fba73': `以App的方式安装到装置，提高用户体验以及操作便捷性`,
'sdfe23506a3b0cdc8': `1) 在Safari上浏览此网页`,
    };
  